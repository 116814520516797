import * as React from 'react';
import * as styles from './styles.module.scss';
import classNames from 'classnames';
import { useLocaleString } from '@devsisters/gatsby-plugin-i18n';

interface DownloadButtons {
  isMobile?: boolean;
}

const DownloadButtons: React.FC<DownloadButtons> = ({ isMobile }) => {
  const locale = useLocaleString();
  const isKo = locale === 'ko';

  return (
    <div className={classNames(styles.Container, isMobile && styles.isMobile)}>
      <div className={styles.DownloadButtons}>
        <a
          href={isKo ? 'https://apple.co/4gmqdUe' : 'https://apple.co/3ZzCcrL'}
          target="_blank"
        >
          <img src={require('../../images/download-buttons/appstore.svg')} />
        </a>
        <a
          href={isKo ? 'https://bit.ly/4iiRsRf' : 'https://bit.ly/41jRp1n'}
          target="_blank"
        >
          <img src={require('../../images/download-buttons/playstore.svg')} />
        </a>
        {isKo && (
          <a href={'https://bit.ly/3ODcmgp'} target="_blank">
            <img src={require('../../images/download-buttons/onestore.svg')} />
          </a>
        )}
        <a
          href={isKo ? 'https://bit.ly/3OGWBVG' : 'https://bit.ly/3OKfgja'}
          target="_blank"
        >
          <img src={require('../../images/download-buttons/galaxystore.png')} />
        </a>
        <a
          href={isKo ? 'https://bit.ly/4ijFfvE' : 'https://bit.ly/3Voiih2'}
          target="_blank"
        >
          <img src={require('../../images/download-buttons/gpg.svg')} />
        </a>
      </div>
    </div>
  );
};

export default DownloadButtons;
